import * as React from 'react';
import { Link } from 'gatsby';
import { MainLayout } from 'components/MainLayout';
import { ContentTitle } from 'components/ContentTitle';
import { Footer } from 'components/Footer';

const About = () => (
  <MainLayout>
    <div className="mb-12 container px-2">
      <ContentTitle title="О нас" backButton />
      <div className="mb-10">
        <div>
          <h1 className="font-semibold">Family Friend - ваш выбор качество жизни.</h1>
          <p className="mt-2">
            Осознанное потребление, свежие и отборные продукты, программа поддержки экологии и ценность времени - это
            то, что Family Friend ежедневно создает для своих клиентов.
          </p>
          <p className="mt-4">Ключевые ценности сервиса Качество, Скорость и Любовь.</p>
        </div>
        <div className="mt-6">
          <h1 className="font-semibold uppercase">КАЧЕСТВО</h1>
          <p className="mt-2">
            Всегда свежие продукты, отборные овощи и фрукты, парное мясо из Ростовской, Калужской областей и Дагестана,
            охлажденная рыба от La Maree, хлеб из наших пекарен и коллекция продуктов от фермеров.
          </p>
          <p className="mt-4">
            Все продукты имеют сертификаты качества. Ежедневно мы обеспечиваем необходимые условия хранения и доставки,
            потому что качество для нас это не просто слова.
          </p>
          <p className="mt-4">
            Если вдруг что-то в заказе не устроило Вас, пожалуйста, сообщите нам. Мы оперативно проверим партию и вернем
            Вам средства за продукт.
          </p>
        </div>
        <div className="mt-6">
          <h1 className="font-semibold uppercase">СКОРОСТЬ</h1>
          <p className="mt-2">Мы доставляем от 1 часа или к назначенному времени. Среднее время доставки – 2 часа</p>
          <p className="mt-4">
            Никогда еще заказ продуктов не был таким простым. Вам не нужно тратить время на поход в магазин, мы выберем
            для Вас самое лучшее и доставим в удобное время
          </p>
        </div>
        <div className="mt-6">
          <h1 className="font-semibold uppercase">ЛЮБОВЬ</h1>
          <p className="mt-2">
            Удобное приложение и сайт, крафт упаковка, программа по переработке тары, акции и интересные программы,
            постоянно расширяемый ассортимент, поддержка по любым вопросам - мы ежедневно работаем над тем, чтобы
            становиться удобнее и надежнее для Вас.
          </p>
          <p className="mt-6 font-semibold">Просто попробуйте и вы останетесь с нами навсегда.</p>
          <p className="mt-2">Заказы доставляются с 8:00 до 22:00.</p>
        </div>
      </div>
      <div className="flex">
        <Link to="/" className="btn btn-orange">
          Перейти в магазин
        </Link>
      </div>
    </div>
    <Footer />
  </MainLayout>
);

export default About;
